@tailwind base;
@tailwind components;
@tailwind utilities;

@import "https://fonts.googleapis.com/css2?family=Luckiest+Guy:wght@300;400;500;600;700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap";

@font-face {
  font-family: "Bauer";
  src: url("./assets/font/Bauer.otf") format("otf");
}

body {
  margin: 0;
  padding: 0;
  font-family: Luckiest Guy, cursive;
  background-color: #928062;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
  color: #fff;
}
.info,
input {
  font-family: Luckies Guy, sans-serif;
}
input,
select {
  color: #000;
}

.bg-share {
  background-image: url(./assets/share.gif);
  background-repeat: no-repeat;
  background-size: contain;
  background-blend-mode: luminosity;
  border-radius: 2px;
  background-color: #534026;
}
